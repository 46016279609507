import styled, { css } from 'styled-components'
import { Loader } from 'semantic-ui-react'
import { Link } from 'gatsby'
import environment from '../utils/environment'

export const Wrapper = styled.div`
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  width: 100%;
  background-color: ${({ theme }) => theme.colors.white};
`

interface SectionProps {
  alignSelf?: string
  maxWidth?: string
  textAlign?: string
  black?: boolean
  white?: string
  rowWrap?: boolean
  isVip?: boolean
}

export const Section = styled.div<SectionProps>`
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  align-items: center;
  align-self: ${props => props.alignSelf};
  background-color: ${({ theme }) => theme.colors.white};
  max-width: ${props => props.maxWidth};
  text-align: ${props => props.textAlign};
  width: 100%;

  ${props =>
    props.black &&
    css`
      color: ${props.theme.colors.white};
      background-color: ${props.theme.colors.black};
    `}

  ${props =>
    props.rowWrap &&
    css`
      flex-flow: row wrap;
      padding-bottom: 10px;
      width: 100%;
    `}

  ${props =>
    props.isVip &&
    css`
      color: ${props.theme.colors.white};
      background: ${props.theme.colors.vipDarkBlue};
    `}
`

interface ContainerProps {
  column?: boolean
}

export const Container = styled.div<ContainerProps>`
  display: flex;
  flex-flow: ${props => (props.column ? 'column nowrap' : 'row nowrap')};
  justify-content: center;
  align-items: center;
  max-width: 600px;
  font-size: 1.2em;
  text-align: center;
  padding: 1em 2em 2em 2em;
`

export const ProductsGrid = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  justify-content: space-evenly;
  gap: 1em 0;

  @media ${({ theme }) => theme.device.xs} {
    grid-template-columns: 1fr 1fr;
  }
  @media ${({ theme }) => theme.device.sm} {
    grid-template-columns: 1fr 1fr 1fr;
  }
  @media ${({ theme }) => theme.device.lg} {
    grid-template-columns: 1fr 1fr 1fr 1fr;
  }
`

export const CardsContainer = styled.div`
  display: flex;
  flex-direction: column;

  @media ${({ theme }) => theme.device.xs} {
    flex: 1 0 calc(50vw - 10px);
  }
  @media ${({ theme }) => theme.device.sm} {
    flex: 1 0 calc(25vw - 10px);
  }
`

interface TitleProps {
  padBottom?: string
  white?: boolean
}

export const Title = styled.span<TitleProps>`
  font-size: 24px;
  font-weight: bold;
  align-self: center;
  padding: 1em 0;
  padding-bottom: ${props => props.padBottom};
  color: ${props =>
    props.white ? props.theme.colors.white : props.theme.colors.black};
`

export const TitleContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 20px;
  margin: 1em 0;
  width: 100vw;
`

export const LoaderWrapper = styled(Loader)`
  position: relative !important;
  grid-column: 1/3;
  padding-bottom: 20px;

  @media ${({ theme }) => theme.device.xs} {
    grid-column: 1/3;
  }
  @media ${({ theme }) => theme.device.sm} {
    grid-column: 1/4;
  }
  @media ${({ theme }) => theme.device.lg} {
    grid-column: 1/5;
  }
`

export const CategoriesGrid = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  justify-content: space-evenly;
  gap: 1em 0;

  @media ${({ theme }) => theme.device.xs} {
    grid-template-columns: 1fr 1fr;
  }
  @media ${({ theme }) => theme.device.sm} {
    grid-template-columns: 1fr 1fr 1fr;
  }
  @media ${({ theme }) => theme.device.lg} {
    grid-template-columns: 1fr 1fr 1fr 1fr;
  }
`

interface ProductCategoryHeaderProps {
  backgroundImg?: string
}

export const ProductCategoryHeader = styled.div<ProductCategoryHeaderProps>`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: calc(100vw * 0.6);
  max-height: 400px;
  margin-bottom: 20px;
  ${props =>
    props.backgroundImg &&
    css`
      background: url(${props.backgroundImg});
      background-size: cover;
      background-repeat: no-repeat;
      background-position: center;
    `}
`

export const ProductCategoryTitle = styled.div`
  font-size: 48px;
  font-weight: bold;
  line-height: 50px;
  text-align: center;
  text-transform: uppercase;
  color: ${({ theme }) => theme.colors.white};
  @media ${({ theme }) => theme.device.sm} {
    font-size: 60px;
  }
  @media ${({ theme }) => theme.device.lg} {
    font-size: 72px;
  }
`

export const ShopAllButton = styled(Link)`
  &&& {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 1.2em;
    font-weight: bolder;
    padding: 5px 2em;
    color: ${({ theme }) =>
      environment.IS_VIP_SITE
        ? theme.colors.vipBlue
        : theme.colors.metallicSeaweed};
    border: 2px solid
      ${({ theme }) =>
        environment.IS_VIP_SITE
          ? theme.colors.vipBlue
          : theme.colors.metallicSeaweed};
    border-radius: 50px;
    margin: 1em;

    :active {
      text-decoration: none;
    }
    :visited {
      text-decoration: none;
    }
    :hover {
      color: ${({ theme }) => theme.colors.white};
      border: 2px solid
        ${({ theme }) =>
          environment.IS_VIP_SITE
            ? theme.colors.vipBlue
            : theme.colors.metallicSeaweed};
      background-color: ${({ theme }) =>
        environment.IS_VIP_SITE
          ? theme.colors.vipBlue
          : theme.colors.metallicSeaweed};
    }
  }
`

export const ImageOverlay = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.5);
  height: 100%;
  width: 100%;
`
